import axios from "axios";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../contexts/use-auth";

export default function AuthRequired(props: { children: JSX.Element }) {
  const location = useLocation();
  const auth = useAuth();

  const intercept = (access_token: string) => {
    axios.interceptors.request.use(
      function (config) {
        config.headers["Authorization"] = `Bearer ${access_token}`;
        config.headers["Content-Type"] = "application/json";
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  };

  if (!auth.jwt) {
    console.log('Not authenticated, redirect to login page...');
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/intro" state={{ from: location }} replace />;
  } else {
    intercept(auth.jwt.access_token);
  }

  return props.children;
}