import { ChevronLeftIcon, PencilAltIcon } from "@heroicons/react/outline";
import moment from "moment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createFriendship } from "../business/api/friendship-service";
import FriendshipDto from "../business/dto/friendship-dto";
import PostDetails from "../business/dto/post-details";
import PostNewEdit from "../business/dto/post-new-edit";
import IosNavbar from "../components/ios/IosNavbar";
import IosPage from "../components/ios/IosPage";

export default function NewFriend() {
  const [username, setUsername] = useState<string>("");
  const navigate = useNavigate();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUsername(e.target.value);
  };

  const onCreateClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    createFriendship(username).then((friendship: FriendshipDto) => {
      navigate("/friends", { replace: true });
    });
  };

  return (
    <form onSubmit={onCreateClicked}>
      <IosPage>
        <IosNavbar
          title="New Friendrequest"
          leftAction={
            <>
              <Link to="/friends" replace={true}>
                Cancel
              </Link>
            </>
          }
          rightAction={
            <button type="submit" className="font-normal">
              Add
            </button>
          }
        />
        <div className="bg-white dark:bg-gray-800">
          <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
              <span className="text-gray-400 dark:text-gray-500">
                Username:
              </span>
            </div>
            <input
              type="text"
              name="username"
              id="username"
              className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 pl-[118px] pr-6 py-3"
              value={username}
              onChange={handleInputChange}
              autoFocus
            />
          </div>
          <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
        </div>
      </IosPage>
    </form>
  );
}
