import axios from "axios";
import PostDetails from "../dto/post-details";
import PostNewEdit from "../dto/post-new-edit";
import PostRef from "../dto/post-ref";

const ressourceUrl = "/api/posts";

export const createPost = async (data: PostNewEdit): Promise<PostRef> => {
  return axios.post(ressourceUrl, data).then((response) => response.data);
};

export const loadPost = async (id: string): Promise<PostDetails> => {
  return axios.get(`${ressourceUrl}/${id}`).then((response) => response.data);
};

export const updatePost = async (
  id: string,
  data: PostNewEdit
): Promise<PostRef> => {
  return axios
    .put(`${ressourceUrl}/${id}`, data)
    .then((response) => response.data);
};

export const deletePost = async (id: string): Promise<any> => {
  return axios
    .delete(`${ressourceUrl}/${id}`)
    .then((response) => response.data);
};

export const createShare = async (
  postId: string,
  userId: string
): Promise<any> => {
  return axios
    .post(`${ressourceUrl}/${postId}/shares`, { userId: userId })
    .then((response) => response.data);
};

export const deleteShare = async (
  postId: string,
  userId: string
): Promise<any> => {
  return axios
    .delete(`${ressourceUrl}/${postId}/shares/${userId}`)
    .then((response) => response.data);
};

export const createLike = async (postId: string): Promise<any> => {
  return axios
    .post(`${ressourceUrl}/${postId}/likes`)
    .then((response) => response.data);
};

export const deleteLike = async (postId: string): Promise<any> => {
  return axios
    .delete(`${ressourceUrl}/${postId}/likes`)
    .then((response) => response.data);
};
