import { ChevronRightIcon, PencilAltIcon } from "@heroicons/react/outline";
import { LockClosedIcon, ShareIcon } from "@heroicons/react/solid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { findAllMyPosts } from "../business/api/me-service";
import PostRef from "../business/dto/post-ref";
import { getKey, getKeyMaterial } from "../business/pbkdf2";
import IosNavbar from "../components/ios/IosNavbar";
import IosPage from "../components/ios/IosPage";
import IosSearch from "../components/ios/IosSearch";

export default function Dreams() {
  const [posts, setPosts] = useState(null);
  const [search, setSearch] = useState("");
  const [touched, setTouched] = useState(-1);

  useEffect(() => {
    findAllMyPosts().then((posts: PostRef[]) => setPosts(posts));

    /*
    setTimeout(async () => {
      let keyMaterial = await getKeyMaterial();
      
      var enc = new TextEncoder();
      let salt = enc.encode("fabian@steinert.io");
      let key = await getKey(keyMaterial, salt);
      console.log('key:', key, JSON.stringify(key));
    });
    */

  }, []);

  const filter = (posts: PostRef[]) => {
    return posts.filter(
      (post: PostRef) =>
        post.title.toLowerCase().includes(search.toLowerCase()) ||
        post.description.toLowerCase().includes(search.toLowerCase())
    );
  };

  return (
    <IosPage>
      <IosNavbar
        title={`Dreams ${posts ? `(${filter(posts).length})` : ""}`}
        rightAction={
          <Link to="/dreams/new" replace={true}>
            <PencilAltIcon className="h-6 w-6" />
          </Link>
        }
      >
        {<IosSearch placeholder="Search dreams" search={search} setSearch={setSearch} />}
      </IosNavbar>
      <div className="border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
      <ul role="list" className="bg-white dark:bg-gray-800">
        {posts &&
          React.Children.toArray(
            filter(posts).map((post: PostRef, index: number) => (
              <li>
                {index !== 0 && (
                  <div
                    className={
                      index === touched || index === touched + 1 ? "" : "pl-6"
                    }
                  >
                    <div className="w-full border-t border-gray-200 dark:border-gray-50/[0.06]"></div>
                  </div>
                )}
                <div className="relative">
                  <div
                    className={`transition relative bg-white dark:bg-gray-800 py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600 ${touched === index ? "bg-gray-200" : ""
                      }`}
                  >
                    <div className="flex items-center justify-between space-x-3">
                      <div className="min-w-0 flex-1">
                        <div className="block focus:outline-none">
                          <Link
                            to={post.id}
                            replace={true}
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          <div className="flex">
                            <div className="min-w-[24px] flex justify-center items-center">
                              {!post.shared && (
                                <LockClosedIcon className="h-3 h-3 text-green-500" />
                              )}
                              {post.shared && (
                                <ShareIcon className="h-3 h-3 text-yellow-500" />
                              )}
                              {false && (
                                <span className="h-2 w-2 bg-blue-500 rounded-full"></span>
                              )}
                            </div>
                            <p className="font-medium text-gray-900 dark:text-gray-200 truncate">
                              {post.title}
                            </p>
                          </div>
                          {false && (
                            <p className="pl-6 text-sm text-gray-900 dark:text-gray-200 truncate">
                              {post.title}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex-shrink-0 flex whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        <time dateTime={moment(post.date).format()}>
                          {moment(post.date).fromNow()}
                        </time>
                        <div className="min-w-[24px]">
                          <ChevronRightIcon
                            className="h-5 w-5 mr-1 text-gray-400 dark:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-1 px-6">
                      <p className="line-clamp-3 text-sm text-gray-500 dark:text-gray-400">
                        {post.description}
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            ))
          )}
      </ul>

      <div className="border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
    </IosPage>
  );
}
