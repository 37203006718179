import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const IosBottomNavigation = (props: { children }) => {
  const location = useLocation();
  const [threshold, setThreshold] = useState(
    window.scrollY + window.innerHeight >= document.body.clientHeight
  );

  useEffect(() => {
    const onScroll = () =>
      setThreshold(
        window.scrollY + window.innerHeight >= document.body.clientHeight
      );
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, [location]);

  return (
    <>
      <div className="h-[49px] bg-gray-100 dark:bg-gray-900"></div>
      <nav className="fixed bottom-0 left-0 right-0 z-40">
        <div className="max-w-4xl mx-auto">
          <div
            className={`h-[48px] flex justify-between items-center backdrop-blur bg-gray-100 dark:bg-gray-900/75 border-t ${
              threshold
                ? "border-gray-100 dark:border-gray-900"
                : "border-gray-200 dark:border-gray-50/[0.06]"
            }`}
          >
            {props.children}
          </div>
        </div>
      </nav>
    </>
  );
};

export default IosBottomNavigation;
