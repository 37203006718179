import axios from "axios";
import AccountDetails from "../dto/account-details";
import FriendshipDto from "../dto/friendship-dto";
import PostOverview from "../dto/post-overview";
import PostRef from "../dto/post-ref";

const ressourceUrl = "/api/me";

export const loadMe = async (): Promise<AccountDetails> => {
  return axios.get(ressourceUrl).then(response => response.data);
};

export const findAllMyPosts = async (): Promise<PostRef[]> => {
  return axios.get(`${ressourceUrl}/posts`).then(response => response.data);
};

export const findAllMyPostsSharedWithMe = async (): Promise<PostOverview[]> => {
  return axios.get(`${ressourceUrl}/explore`).then(response => response.data);
};

export const findPostsSharedWithMe = async (postId: string): Promise<PostOverview> => {
  return axios.get(`${ressourceUrl}/explore/${postId}`).then(response => response.data);
};

export const findAllMyFriendships = async (): Promise<FriendshipDto[]> => {
  return axios.get(`${ressourceUrl}/friendships`).then(response => response.data);
};