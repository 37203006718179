import { Outlet } from "react-router-dom";
import {
  ViewGridIcon as ViewGridIconOutline,
  MoonIcon as MoonIconOutline,
  UserGroupIcon as UserGroupIconOutline,
  UserCircleIcon as UserCircleIconOutline,
} from "@heroicons/react/outline";
import {
  ViewGridIcon as ViewGridIconSolid,
  MoonIcon as MoonIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  UserCircleIcon as UserCircleIconSolid,
} from "@heroicons/react/solid";
import IosBottomNavigation from "../components/ios/IosBottomNavigation";
import IosBottomNavigationItem from "../components/ios/IosBottomNavigationItem";

const tabItems = [
  {
    label: "Explore",
    to: "/explore",
    end: false,
    icon: ViewGridIconOutline,
    iconActive: ViewGridIconSolid,
  },
  {
    label: "Dreams",
    to: "/dreams",
    end: false,
    icon: MoonIconOutline,
    iconActive: MoonIconSolid,
  },
  {
    label: "Friends",
    to: "/friends",
    end: false,
    icon: UserGroupIconOutline,
    iconActive: UserGroupIconSolid,
  },
  {
    label: "Account",
    to: "/account",
    end: false,
    icon: UserCircleIconOutline,
    iconActive: UserCircleIconSolid,
  },
];

export default function Layout() {
  return (
    <div className="max-w-4xl mx-auto">
      <Outlet />
      <IosBottomNavigation>
        {tabItems.map((item) => (
          <IosBottomNavigationItem
            label={item.label}
            to={item.to}
            end={item.end}
            icon={item.icon}
            iconActive={item.iconActive}
          />
        ))}
      </IosBottomNavigation>
    </div>
  );
}
