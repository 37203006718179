import React, { useEffect } from "react";
import { obtainToken } from "../business/api/auth-service";
import Login from "../business/dto/login";
import { AuthContext } from "../contexts/auth-context";

export default function AuthProvider(props: { children: React.ReactNode }) {
  const [jwt, setJwt] = React.useState<any>(
    () => JSON.parse(localStorage.getItem("token")) || ""
  );

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(jwt));
  }, [jwt]);

  const signin = async (data: Login, callback: VoidFunction): Promise<any> => {
    return obtainToken(data)
      .then((jwt) => {
        setJwt(jwt);
        callback();
      })
      .catch((_) => {});
  };

  const signout = async (callback: VoidFunction): Promise<any> => {
    return new Promise((resolve, reject) => {
      setJwt(null);
      callback();
      resolve(true);
    });
  };

  const value = { jwt, signin, signout };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
