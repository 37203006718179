import React from "react";

export default function IosListGroup(props: {
  children: React.ReactNode;
  title?: string;
  description?: string;
}) {
  return (
    <div className="mx-3 mb-6 space-y-1">
      {props.title && (
        <div className="text-xs mx-3 text-gray-500 dark:text-gray-400 uppercase">
          {props.title}
        </div>
      )}
      <ul
        role="list"
        className="bg-white dark:bg-gray-800 rounded-xl overflow-hidden flex flex-col-reverse"
      >
        {React.Children.toArray(props.children).reverse()}
      </ul>
      {props.description && (
        <div className="text-xs mx-3 text-gray-500 dark:text-gray-400">
          {props.description}
        </div>
      )}
    </div>
  );
}
