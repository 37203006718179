import IosDivider from "./IosDivider";

export default function IosActionSheetItem(props: {
  title: string;
  color?: string;
  action?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <button
      type="button"
      onClick={props.action}
      className="relative group w-full min-h-[56px] flex items-center justify-center"
    >
      <div
        className={`text-lg ${
          props.color ? props.color : "text-gray-900 dark:text-gray-300"
        }`}
      >
        {props.title}
      </div>
      <div className="group-last:hidden absolute right-0 bottom-0 left-0">
        <IosDivider />
      </div>
    </button>
  );
}
