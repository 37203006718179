import { ChevronRightIcon, PlusIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import {
  deleteFriendship,
  updateFriendship,
} from "../business/api/friendship-service";
import { findAllMyFriendships } from "../business/api/me-service";
import FriendshipDto from "../business/dto/friendship-dto";
import IosActionSheet from "../components/ios/IosActionSheet";
import IosActionSheetItem from "../components/ios/IosActionSheetItem";
import IosListGroup from "../components/ios/IosListGroup";
import IosListGroupItem from "../components/ios/IosListGroupItem";
import IosNavbar from "../components/ios/IosNavbar";
import IosPage from "../components/ios/IosPage";
import IosSearch from "../components/ios/IosSearch";
import Page from "./Page";

const tabs = [
  { name: "Friends", href: "#", current: false },
  { name: "Requested", href: "#", current: false },
  { name: "Received", href: "#", current: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Friends() {
  const [friendships, setFriendships] = useState(null);
  const [search, setSearch] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    findAllMyFriendships().then((friendships: FriendshipDto[]) => {
      setFriendships(friendships);
    });
  }, []);

  const filter = (friendships: FriendshipDto[]) => {
    return friendships.filter(
      (friendship: FriendshipDto) =>
        friendship.user.firstname
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        friendship.user.username.toLowerCase().includes(search.toLowerCase())
    );
  };

  const onDeleteClick = (friendship: FriendshipDto) => {
    deleteFriendship(friendship.id).then(() => {
      setFriendships(friendships.filter((x) => x.id != friendship.id));
    });
  };

  const onAcceptClick = (friendship: FriendshipDto) => {
    updateFriendship(friendship.id).then(() => {
      friendship.accepted = true;
      setFriendships([...friendships]);
    });
  };

  const isFriend = (friendship: FriendshipDto) => friendship.accepted;
  const isReceived = (friendship: FriendshipDto) =>
    !friendship.accepted && !friendship.sender;
  const isRequested = (friendship: FriendshipDto) =>
    !friendship.accepted && friendship.sender;
  const getFriends = () =>
    friendships.filter((friendship) => isFriend(friendship));
  const getReceived = () =>
    friendships.filter((friendship) => isReceived(friendship));
  const getRequested = () =>
    friendships.filter((friendship) => isRequested(friendship));

  const filterByTab = () => {
    if (tabIndex === 0) return getFriends();
    if (tabIndex === 1) return getReceived();
    if (tabIndex === 2) return getRequested();
    return [];
  };

  return (
    <IosPage>
      <IosNavbar
        title={`Friends ${
          friendships ? `(${filter(friendships).length})` : ""
        }`}
        rightAction={
          <Link to="/friends/new" replace={true}>
            <PlusIcon className="h-6 w-6" />
          </Link>
        }
      >
        {<IosSearch placeholder="Search friends" search={search} setSearch={setSearch} />}
      </IosNavbar>
      {false && (
        <ul role="list" className="relative bg-gray-800">
          {friendships &&
            filterByTab().map((friendship) => (
              <li
                key={friendship.id}
                className="border-b border-gray-200 dark:border-gray-50/[0.06]"
              >
                <div className="relative px-6 py-3">
                  <div className="flex items-center space-x-3">
                    <div className="flex-shrink-0">
                      <img
                        className="h-10 w-10 rounded-full"
                        src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt=""
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <p className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">
                        {friendship.user.firstname}
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                        @{friendship.user.username}
                      </p>
                    </div>
                    {friendship.accepted && (
                      <div className="flex-shrink-0">
                        <button
                          onClick={() => onDeleteClick(friendship)}
                          className="text-sm text-red-500 hover:text-red-600 dark:text-red-500 dark:hover:text-red-400"
                        >
                          Remove
                        </button>
                      </div>
                    )}
                    {!friendship.accepted && friendship.sender && (
                      <div className="flex-shrink-0">
                        <button
                          onClick={() => onDeleteClick(friendship)}
                          className="text-sm text-yellow-500 hover:text-yellow-600 dark:text-yellow-500 dark:hover:text-yellow-400"
                        >
                          Revoke
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {!friendship.accepted && !friendship.sender && (
                  <div className="flex items-center justify-center space-x-6 pb-3">
                    <button
                      onClick={() => onAcceptClick(friendship)}
                      className="text-sm text-green-500 hover:text-green-600 dark:text-green-500 dark:hover:text-green-400"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => onDeleteClick(friendship)}
                      className="text-sm text-red-500 hover:text-red-600 dark:text-red-500 dark:hover:text-red-400"
                    >
                      Reject
                    </button>
                  </div>
                )}
              </li>
            ))}
        </ul>
      )}

      {friendships && (
        <>
          {filter(getReceived()).length > 0 && (
            <IosListGroup title="friend request received" description="">
              {React.Children.toArray(
                filter(getReceived()).map((item) => (
                  <IosListGroupItem
                    key={item.id}
                    title={item.user.firstname}
                    subtitle={`@${item.user.username}`}
                    img="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    action={(e) => {
                      setSelected(item);
                      setOpen(true);
                    }}
                    extra={`${item.user.dreams} ${
                      item.user.dreams === 1 ? "dream" : "dreams"
                    }`}
                  />
                ))
              )}
            </IosListGroup>
          )}
          {filter(getRequested()).length > 0 && (
            <IosListGroup title="friend request sent" description="">
              {React.Children.toArray(
                filter(getRequested()).map((item) => (
                  <IosListGroupItem
                    key={item.id}
                    title={item.user.firstname}
                    subtitle={`@${item.user.username}`}
                    img="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    action={(e) => {
                      setSelected(item);
                      setOpen(true);
                    }}
                    extra={`${item.user.dreams} ${
                      item.user.dreams === 1 ? "dream" : "dreams"
                    }`}
                  />
                ))
              )}
            </IosListGroup>
          )}
          {filter(getFriends()).length > 0 && (
            <IosListGroup>
              {React.Children.toArray(
                filter(getFriends()).map((item) => (
                  <IosListGroupItem
                    key={item.id}
                    title={item.user.firstname}
                    subtitle={`@${item.user.username}`}
                    img="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    action={(e) => {
                      setSelected(item);
                      setOpen(true);
                    }}
                    extra={`${item.user.dreams} ${
                      item.user.dreams === 1 ? "dream" : "dreams"
                    }`}
                  />
                ))
              )}
            </IosListGroup>
          )}
        </>
      )}

      <IosActionSheet open={open} setOpen={setOpen}>
        {selected && isFriend(selected) && (
          <>
            <IosActionSheetItem
              title="Remove"
              color="text-red-500"
              action={() => {
                onDeleteClick(selected);
                setOpen(false);
              }}
            />
          </>
        )}
        {selected && isReceived(selected) && (
          <>
            <IosActionSheetItem
              title="Accept"
              color="text-green-500"
              action={() => {
                onAcceptClick(selected);
                setOpen(false);
              }}
            />
            <IosActionSheetItem
              title="Reject"
              color="text-red-500"
              action={() => {
                onDeleteClick(selected);
                setOpen(false);
              }}
            />
          </>
        )}
        {selected && isRequested(selected) && (
          <>
            <IosActionSheetItem
              title="Revoke"
              color="text-yellow-500"
              action={() => {
                onDeleteClick(selected);
                setOpen(false);
              }}
            />
          </>
        )}
      </IosActionSheet>
    </IosPage>
  );
}
