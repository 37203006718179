import { ChevronRightIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IosBottomNavigation from "../components/ios/IosBottomNavigation";
import IosNavbar from "../components/ios/IosNavbar";
import IosPage from "../components/ios/IosPage";
import IosTitle from "../components/ios/IosTitle";
import useAuth from "../contexts/use-auth";
import Page from "./Page";

const steps = [
  {
    title: "Dream Together",
    description:
      "Easily create, manage and share the adventures of your dreams in no time and see in which adventures of your friends you appeared.",
    image: "assets/img/undraw_horror_movie_3988.svg",
  },
  {
    title: "Capture Adventures",
    description:
      "If you don't write down your dreams, you'll forget them as soon as you wake up. Maintain a dream journal is an important step in not immediately forgetting your dreams and being able to revive them in the future.",
    image: "assets/img/undraw_fashion_photoshoot_mtq8.svg",
  },
  {
    title: "Partners in Crime",
    description:
      "Team up with your favorite accomplices and experience exciting adventures. Add your friends and share the adventures you've had and tag them directly as participants within your dream.",
    image: "assets/img/undraw_conversation_re_c26v.svg",
  },
  {
    title: "Don't Tell Everybody",
    description:
      "Not all adventures are for all ears. You decide which ears may hear about them. Dreams can be kept private or shared with your friends or just specific ones.",
    image: "assets/img/undraw_dark_alley_hl-3-o.svg",
  },
];

export default function Intro() {
  const [index, setIndex] = useState(0);

  return (
    <div className="max-w-4xl mx-auto">
      <IosPage>
        <IosNavbar
          title={steps[index].title}
          leftAction={
            index > 0 && (
              <button onClick={() => setIndex(index - 1)}>Previous</button>
            )
          }
          rightAction={
            index < steps.length - 1 ? (
              <button onClick={() => setIndex(index + 1)}>Next</button>
            ) : (
              <Link to="/login" replace={true}>
                Finish
              </Link>
            )
          }
        />
        <p className="px-6 text-sm text-gray-600 dark:text-gray-500 mb-6">
          {steps[index].description}
        </p>

        <div className="px-6">
          <img
            src={steps[index].image}
            className="mb-6 w-auto mx-auto"
            style={{ height: "200px" }}
          ></img>
        </div>
      </IosPage>
      <IosBottomNavigation>
        <div
          className="flex items-center w-full h-full justify-center"
          aria-label="Progress"
        >
          <ol role="list" className="flex items-center space-x-5">
            {steps.map((step, i) => (
              <li key={i}>
                {i < index ? (
                  <a className="block w-2.5 h-2.5 bg-teal-500 rounded-full">
                    <span className="sr-only">{steps[index].title}</span>
                  </a>
                ) : i === index ? (
                  <a
                    className="relative flex items-center justify-center"
                    aria-current="step"
                  >
                    <span
                      className="absolute w-5 h-5 p-px flex"
                      aria-hidden="true"
                    >
                      <span className="w-full h-full rounded-full bg-teal-700" />
                    </span>
                    <span
                      className="relative block w-2.5 h-2.5 bg-teal-500 rounded-full"
                      aria-hidden="true"
                    />
                    <span className="sr-only">{steps[index].title}</span>
                  </a>
                ) : (
                  <a className="block w-2.5 h-2.5 bg-gray-200 bg-gray-700 rounded-full">
                    <span className="sr-only">{steps[index].title}</span>
                  </a>
                )}
              </li>
            ))}
          </ol>
        </div>
      </IosBottomNavigation>
    </div>
  );
}
