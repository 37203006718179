import { CheckIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import {
  BellIcon,
  BookmarkIcon,
  ColorSwatchIcon,
  DatabaseIcon,
  DeviceMobileIcon,
  EmojiHappyIcon,
  FolderIcon,
  GlobeAltIcon,
  LockClosedIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IosNavbar from "../../components/ios/IosNavbar";
import IosPage from "../../components/ios/IosPage";

const items = [
  [
    {
      name: "Light",
      value: "light",
      href: "#",
      iconColor: "bg-blue-500",
      icon: BookmarkIcon,
      extra: "",
    },
    {
      name: "Dark",
      value: "dark",
      href: "#",
      iconColor: "bg-green-500",
      icon: PhoneIcon,
      extra: "",
    },
  ],
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Appearance() {
  const [theme, setTheme] = useState("dark");

  const onThemeSelected = (theme: string) => {
    setTheme(theme);
    document.documentElement.className = "";
    document.documentElement.classList.add(theme);
  }

  return (
    <IosPage>
      <IosNavbar
        title={"Appearance"}
        leftAction={
          <Link
            to="/account"
            replace={true}
            className="flex items-center ml-[-24px]"
          >
            <ChevronLeftIcon className="h-7 w-7"></ChevronLeftIcon>
            <span className="m-[-4px]">Account</span>
          </Link>
        }
      />

      {React.Children.toArray(
        items.map((x) => (
          <ul
            role="list"
            className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-50/[0.06] rounded-lg mx-3 mb-6 overflow-hidden"
          >
            {React.Children.toArray(
              x.map((item, index) => (
                <li>
                  {index !== 0 && (
                    <div className="pl-6">
                      <div className="w-full border-t border-gray-200 dark:border-gray-50/[0.06]"></div>
                    </div>
                  )}
                  <div onClick={() => onThemeSelected(item.value)} className="relative group py-2 px-6 flex items-center hover:bg-gray-200 dark:hover:bg-slate-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <div className="min-w-0 flex-1">
                      <div className="text-gray-900 dark:text-gray-200">
                        {item.name}
                      </div>
                      {false && <p className="text-sm text-gray-500"></p>}
                    </div>
                    {theme === item.value && <div className="flex-shrink-0 flex items-center">
                      <div className="text-gray-500 dark:text-gray-500 mr-1">
                        {item.extra}
                      </div>
                      <CheckIcon
                        className="h-5 w-5 text-teal-400"
                        aria-hidden="true"
                      />
                    </div>}
                  </div>
                </li>
              ))
            )}
          </ul>
        ))
      )}
    </IosPage>
  );
}
