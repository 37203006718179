import axios from "axios";
import FriendshipDto from "../dto/friendship-dto";

const ressourceUrl = "/api/friendships";

export const createFriendship = async (username: string): Promise<FriendshipDto> => {
  return axios
    .post(ressourceUrl, { username })
    .then((response) => response.data);
};

export const updateFriendship = async (id: string): Promise<FriendshipDto> => {
  return axios.put(`${ressourceUrl}/${id}`).then((response) => response.data);
};

export const deleteFriendship = async (id: string): Promise<any> => {
  return axios
    .delete(`${ressourceUrl}/${id}`)
    .then((response) => response.data);
};
