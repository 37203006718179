import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthProvider from "./components/AuthProvider";
import AuthRequired from "./components/AuthRequired";
import Intro from "./pages/Intro";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Layout from "./pages/Layout";
import Explore from "./pages/Explore";
import Dreams from "./pages/Dreams";
import Dream from "./pages/Dream";
import NewEditDream from "./pages/NewEditDream";
import Friends from "./pages/Friends";
import NewFriend from "./pages/NewFriend";
import Account from "./pages/account/Account";
import Appearance from "./pages/account/Appearance";
import EditAccount from "./pages/account/EditAccount";
import Admin from "./pages/admin/Admin";
import LandingPage from "./pages/landing/LandingPage";
import ExploreDream from "./pages/ExploreDream";

const App = () => {
  return (
    <>
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <AuthRequired>
                <Layout />
              </AuthRequired>
            }
          >
            <Route index element={<Navigate to="/dreams" replace />} />
            <Route path="/dreams" element={<Dreams />} />
            <Route path="/dreams/:id" element={<Dream />} />
            <Route path="/dreams/new" element={<NewEditDream />} />
            <Route path="/dreams/:id/edit" element={<NewEditDream />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/explore/:id" element={<ExploreDream />} />
            <Route path="/friends" element={<Friends />} />
            <Route path="/friends/new" element={<NewFriend />} />
            <Route path="/account" element={<Account />} />
            <Route path="/account/edit" element={<EditAccount />} />
            <Route path="/account/appearance" element={<Appearance />} />
            <Route path="/admin" element={<Admin />} />
          </Route>
          <Route path="/intro" element={<Intro />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/landingpage" element={<LandingPage />} />
        </Routes>
      </AuthProvider>
    </>
  );
};

export default App;
