import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import IosDivider from "./IosDivider";
import IosActionSheetItem from "./IosActionSheetItem";

export default function IosActionSheet(props: {
  children;
  open: any;
  setOpen;
  title?: string;
}) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0" onClose={props.setOpen}>
        <div className="flex items-end justify-center h-full p-0 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="modal-closed ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-full"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-100 translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-100 translate-y-full"
          >
            <div className="dialog-page inline-block align-bottom text-left overflow-hidden transform transition-all max-w-lg w-full">
              <div className="bg-white mx-3 mb-3 dark:bg-gray-800 dark:border-gray-50/[0.06] rounded-xl overflow-hidden">
                {props.title && (
                  <div className="relative flex items-center justify-center py-2">
                    <div className="text-sm text-gray-500 dark_text-gray-400">
                      {props.title}
                    </div>
                    <div className="absolute right-0 bottom-0 left-0">
                      <IosDivider />
                    </div>
                  </div>
                )}
                {props.children}
              </div>
              <div className="bg-white mx-3 mb-3 dark:bg-gray-800 dark:border-gray-50/[0.06] rounded-xl overflow-hidden">
                <IosActionSheetItem
                  title="Cancel"
                  action={() => props.setOpen(false)}
                />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
