import { ChevronRightIcon } from "@heroicons/react/outline";
import React from "react";
import IosDivider from "./IosDivider";

const IosListGroupInputItem = React.forwardRef<HTMLInputElement, any>(
  (
    props: {
      title: string;
    },
    ref
  ) => (
    <li className="relative flex items-center group">
      <label className="basis-full flex items-center" htmlFor={props.title}>
        <div className="relative flex-1 ml-3 min-w-0">
          <div className="group-first:hidden absolute right-0 bottom-0 left-0">
            <IosDivider />
          </div>
          <div className="flex items-center min-h-[44px] pr-3 w-full">
            <div className="flex-1 py-2 overflow-hidden">
              <div className="text-gray-900 dark:text-gray-200 truncate">
                {props.title}
              </div>
            </div>
          </div>
        </div>
      </label>
      <input
        id={props.title}
        ref={ref}
        onFocus={(e) => {
          e.currentTarget.value = e.currentTarget.value;
        }}
        className="basis-full pr-3 border-0 bg-transparent text-gray-500 dark:text-gray-400 outline-none"
        {...props}
      />
    </li>
  )
);

export default IosListGroupInputItem;
