import { ChevronLeftIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import IosNavbar from "../components/ios/IosNavbar";
import IosPage from "../components/ios/IosPage";
import {
  loadPost,
  createShare,
  deleteShare,
  deletePost,
} from "../business/api/post-service";
import PostDetails from "../business/dto/post-details";
import {
  CalendarIcon,
  LockClosedIcon,
  ShareIcon,
  ThumbUpIcon,
} from "@heroicons/react/solid";
import Moment from "react-moment";
import moment from "moment";
import { findAllMyFriendships } from "../business/api/me-service";
import IosListGroup from "../components/ios/IosListGroup";
import IosListGroupItem from "../components/ios/IosListGroupItem";
import FriendshipDto from "../business/dto/friendship-dto";
import UserRef from "../business/dto/user-ref";
import { X } from "react-bootstrap-icons";
import IosActionSheet from "../components/ios/IosActionSheet";
import IosActionSheetItem from "../components/ios/IosActionSheetItem";

export default function Dream() {
  const { id } = useParams();
  const [post, setPost] = useState<PostDetails>(null);
  const [friends, setFriends] = useState<UserRef[]>(null);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    loadPost(id).then((post: PostDetails) => setPost(post));
    findAllMyFriendships().then((friendships: FriendshipDto[]) =>
      setFriends(
        friendships
          .filter((x) => x.accepted)
          .map((friendship) => friendship.user)
      )
    );
  }, []);

  const onDeleteClicked = () => {
    deletePost(id).then(() => {
      navigate("/dreams", { replace: true });
    });
  };

  const onShareClicked = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Hey",
          text: `Ich muss dir unbedingt von meinem Traum ${moment(
            post.date
          ).fromNow()} erzählen... ${
            post.description
          } Ach übrigens halte ich meine Träume mit DreamTogether fest. Kann dir die App nur empfehlen 👍 https://dream.steinert.io/`,
        })
        .then(() => console.log("Successful"))
        .catch((error) => console.log("Error", error));
    }
  };

  const onShareWithFriendClicked = (user: UserRef) => {
    createShare(post.id, user.id)
      .then(() => {
        setPost({ ...post, shared: [user, ...post.shared] });
      })
      .catch((error) => console.log("Error", error));
  };

  const onRemoveShareWithFriendClicked = (user: UserRef) => {
    deleteShare(post.id, user.id)
      .then(() => {
        post.shared.splice(post.shared.indexOf(user), 1);
        setPost({ ...post });
      })
      .catch((error) => console.log("Error", error));
  };

  return (
    <IosPage>
      <IosNavbar
        title={post?.title}
        titleSize="text-2xl"
        leftAction={
          <Link
            to="/dreams"
            replace={true}
            className="flex items-center ml-[-24px]"
          >
            <ChevronLeftIcon className="h-7 w-7"></ChevronLeftIcon>
            <span className="ml-[-4px] pl-[4px]">Dreams</span>
          </Link>
        }
        rightAction={
          <Link to={`/dreams/${post?.id}/edit`} replace={true}>
            Edit
          </Link>
        }
      />
      {post && friends && (
        <>
          <div className="px-6 mb-6">
            <div className="space-y-2 mb-3">
              <div className="flex items-center space-x-2">
                <CalendarIcon
                  className="h-4 w-4 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                />
                <span className="text-gray-500 dark:text-gray-400 text-sm font-medium">
                  <time dateTime="2020-12-02">
                    <Moment date={post.date} format={"lll"} />
                  </time>
                </span>
              </div>
              {!post.shared ||
                (post.shared.length === 0 && (
                  <div className="flex items-center space-x-2">
                    <LockClosedIcon
                      className="h-4 w-4 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="text-green-500 text-sm font-medium">
                      Private
                    </span>
                  </div>
                ))}
              {post.shared && post.shared.length > 0 && (
                <div className="flex items-center space-x-2">
                  <ShareIcon
                    className="h-4 w-4 text-yellow-500"
                    aria-hidden="true"
                  />
                  <span className="text-yellow-500 text-sm font-medium">
                    Shared
                  </span>
                </div>
              )}
              {post.shared && post.shared.length > 0 && (
                <div className="flex items-center space-x-2">
                  <ThumbUpIcon
                    className="h-4 w-4 text-gray-400 dark:text-gray-500"
                    aria-hidden="true"
                  />
                  <span className="text-gray-500 dark:text-gray-400 text-sm font-medium">
                    {post.likes === 1 && "1 like"}
                    {post.likes !== 1 && `${post.likes} likes`}
                  </span>
                </div>
              )}
            </div>

            <div className="text-sm text-gray-500 dark:text-gray-400 whitespace-pre-line">
              {post.description}
            </div>

            <div className="mt-5 text-teal-500">
              <button type="button" onClick={onShareClicked}>
                Share
              </button>
            </div>
          </div>

          {post.shared && post.shared.length > 0 && (
            <IosListGroup title="Shared">
              {React.Children.toArray(
                post.shared.map((user) => (
                  <IosListGroupItem
                    key={user.id}
                    title={user.firstname}
                    subtitle={`@${user.username}`}
                    img="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    action={(e) => onRemoveShareWithFriendClicked(user)}
                  />
                ))
              )}
            </IosListGroup>
          )}
          {friends.some(
            (item) => !post.shared.some((x) => item.id === x.id)
          ) && (
            <IosListGroup title="Share with friends">
              {React.Children.toArray(
                friends
                  .filter((item) => !post.shared.some((x) => item.id === x.id))
                  .map((user) => (
                    <IosListGroupItem
                      key={user.id}
                      title={user.firstname}
                      subtitle={`@${user.username}`}
                      img="https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      action={(e) => onShareWithFriendClicked(user)}
                    />
                  ))
              )}
            </IosListGroup>
          )}

          <ul
            role="list"
            className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-50/[0.06] rounded-lg mx-3 mb-6 overflow-hidden"
          >
            <li>
              <button
                className="relative w-full py-2 px-3 flex items-center justify-center hover:bg-gray-200 dark:hover:bg-slate-700 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600"
                onClick={() => setOpen(true)}
              >
                <span className="absolute inset-0" aria-hidden="true" />
                <div className="text-red-600">Delete</div>
              </button>
            </li>
          </ul>
        </>
      )}

      <IosActionSheet open={open} setOpen={setOpen}>
        <IosActionSheetItem
          title="Delete"
          color="text-red-500"
          action={() => {
            onDeleteClicked();
            setOpen(false);
          }}
        />
      </IosActionSheet>
    </IosPage>
  );
}
