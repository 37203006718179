import { Menu, Transition } from "@headlessui/react";
import { ChevronRightIcon, ThumbUpIcon as ThumbUpIconOutline } from "@heroicons/react/outline";
import { LockClosedIcon, ShareIcon, ThumbUpIcon, ThumbUpIcon as ThumbUpIconSolid } from "@heroicons/react/solid";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { findAllMyPostsSharedWithMe } from "../business/api/me-service";
import PostOverview from "../business/dto/post-overview";
import IosNavbar from "../components/ios/IosNavbar";
import IosPage from "../components/ios/IosPage";
import StackedList from "../components/ios/IosStackedList";

const questions = [
  {
    id: "81614",
    likes: "0",
    replies: "11",
    views: "2.7k",
    author: {
      name: "Fabian",
      imageUrl:
        "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      href: "#",
    },
    date: "December 9 at 11:43 AM",
    datetime: "2020-12-09T11:43:00",
    href: "#",
    title: "What would you have done differently if you ran Jurassic Park?",
    body: `
      <p>Jurassic Park was an incredible idea and a magnificent feat of engineering, but poor protocols and a disregard for human safety killed what could have otherwise been one of the best businesses of our generation.</p>
      <p>Ultimately, I think that if you wanted to run the park successfully and keep visitors safe, the most important thing to prioritize would be&hellip;</p>
    `,
  },
  // More questions...
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Explore() {
  const [posts, setPosts] = useState<PostOverview[]>(null);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<string>(null);

  useEffect(() => {
    findAllMyPostsSharedWithMe().then((posts: PostOverview[]) =>
      setPosts(posts)
    );
  }, []);

  const filter = (posts: PostOverview[]) => {
    return posts.filter(
      (post: PostOverview) =>
        post.title.toLowerCase().includes(search.toLowerCase()) ||
        post.description.toLowerCase().includes(search.toLowerCase())
    );
  };

  return (
    <IosPage>
      <IosNavbar title={"Explore"} />

      {posts && posts.length === 0 && (
        <div className="bg-white dark:bg-gray-800 mx-3 p-6 mb-6 border border-gray-200 dark:border-gray-50/[0.06] rounded-lg">
          <img
            src="assets/img/undraw_horror_movie_3988.svg"
            className="px-12 py-3"
          />
          <h2 className="mt-4 text-base font-medium text-gray-900 dark:text-gray-200">
            Dream Together
          </h2>
          <p className="mt-2 text-sm text-gray-700 dark:text-gray-400 space-y-4">
            See what your friends are dreaming. Add your friends and share the
            adventures of your dreams.
          </p>
        </div>
      )}

      <div className="border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
      <ul role="list" className="bg-white dark:bg-gray-800">
        {posts &&
          React.Children.toArray(
            filter(posts).map((post: PostOverview, index: number) => (
              <li>
                {index !== 0 && (
                  <div className="pl-6"
                  >
                    <div className="w-full border-t border-gray-200 dark:border-gray-50/[0.06]"></div>
                  </div>
                )}
                <div className="relative">
                  <div
                    className={`transition relative bg-white dark:bg-gray-800 py-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-600`}
                  >
                    <div className="flex items-center justify-between space-x-3">
                      <div className="min-w-0 flex-1">
                        <div className="block focus:outline-none">
                          <Link
                            to={post.id}
                            replace={true}
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          <div className="flex">
                            <div className="min-w-[24px] flex justify-center items-center">
                              {!post.read && (
                                <span className="h-2 w-2 bg-teal-500 rounded-full"></span>
                              )}
                            </div>
                            <p className="font-medium text-gray-900 dark:text-gray-200 truncate">
                              {post.author.firstname}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex-shrink-0 flex whitespace-nowrap text-sm text-gray-500 dark:text-gray-400">
                        <time dateTime={moment(post.date).format()}>
                          {moment(post.date).fromNow()}
                        </time>
                        <div className="min-w-[24px]">
                          <ChevronRightIcon
                            className="h-5 w-5 mr-1 text-gray-400 dark:text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="pl-6 text-sm text-gray-900 dark:text-gray-200 truncate">
                      {post.title}
                    </p>
                    <div className="mt-1 px-6">
                      <p className="line-clamp-3 text-sm text-gray-500 dark:text-gray-400">
                        {post.description}
                      </p>
                    </div>
                    <div className="mt-3 px-6 flex items-center space-x-2">
                      {post.liked && (
                        <ThumbUpIcon
                          className="h-5 w-5 text-teal-500"
                          aria-hidden="true"
                        />
                      )}
                      {!post.liked && (
                        <ThumbUpIcon
                          className="h-5 w-5 text-gray-400 dark:text-gray-500"
                          aria-hidden="true"
                        />
                      )}
                      <span className="text-gray-500 dark:text-gray-400 text-sm font-medium">
                        {post.likes === 1 && "1 like"}
                        {post.likes !== 1 && `${post.likes} likes`}
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            ))
          )}
      </ul>

      <div className="border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
    </IosPage>
  );
}
