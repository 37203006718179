import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronLeftIcon,
  PencilAltIcon,
  SelectorIcon,
} from "@heroicons/react/outline";
import moment from "moment";
import React, {
  ChangeEvent,
  Fragment,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { createPost, loadPost, updatePost } from "../business/api/post-service";
import PostDetails from "../business/dto/post-details";
import PostNewEdit from "../business/dto/post-new-edit";
import PostRef from "../business/dto/post-ref";
import IosNavbar from "../components/ios/IosNavbar";
import IosPage from "../components/ios/IosPage";

const people = [
  {
    id: 1,
    name: "Wade Cooper",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "Arlene Mccoy",
    avatar:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    name: "Devon Webb",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
  {
    id: 4,
    name: "Tom Cook",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 5,
    name: "Tanya Fox",
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 6,
    name: "Hellen Schmidt",
    avatar:
      "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 7,
    name: "Caroline Schultz",
    avatar:
      "https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 8,
    name: "Mason Heaney",
    avatar:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 9,
    name: "Claudie Smitham",
    avatar:
      "https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 10,
    name: "Emil Schaefer",
    avatar:
      "https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function NewEditDream() {
  const { id } = useParams();
  const textarea = useRef<HTMLTextAreaElement>();
  const [post, setPost] = useState<PostNewEdit>({
    title: "",
    description: "",
    date: new Date(),
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      loadPost(id).then((post: PostDetails) => {
        setPost({
          title: post.title,
          description: post.description,
          date: post.date,
        });
        calcTextareaHeight();
      });
    }
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPost({ ...post, [e.target.name]: e.target.value });
  };

  const onActionClicked = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (id) {
      updatePost(id, post).then((_: PostRef) => {
        navigate(`/dreams/${id}`, { replace: true });
      });
    } else {
      createPost(post).then((_: PostRef) => {
        navigate("/dreams", { replace: true });
      });
    }
  };

  const calcTextareaHeight = () => {
    textarea.current.style.height = "144px";
    textarea.current.style.height = textarea.current.scrollHeight + "px";
  };

  const [selected, setSelected] = useState(people[3]);

  return (
    <form onSubmit={onActionClicked}>
      <IosPage>
        <IosNavbar
          title={id ? "Edit Dream" : "New Dream"}
          leftAction={
            <Link to={id ? `/dreams/${id}` : "/dreams"} replace={true}>
              Cancel
            </Link>
          }
          rightAction={
            <button type="submit" className="font-normal">
              Done
            </button>
          }
        />
        <div className="bg-white dark:bg-gray-800 mb-6">
          <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
              <span className="text-gray-400 dark:text-gray-500">Time:</span>
            </div>
            <input
              id="date"
              name="date"
              type="datetime-local"
              className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 pl-[78px] pr-6 py-3"
              value={moment(post.date).format("YYYY-MM-DDTHH:mm")}
              onChange={handleInputChange}
            />
          </div>
          <div className="pl-6">
            <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
          </div>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-6 flex items-center pointer-events-none">
              <span className="text-gray-400 dark:text-gray-500">Titel:</span>
            </div>
            <input
              type="text"
              name="title"
              id="title"
              className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 pl-[78px] pr-6 py-3"
              value={post.title}
              onChange={handleInputChange}
              autoFocus
            />
          </div>
          <div className="pl-6">
            <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
          </div>
          <div className="">
            <textarea
              ref={textarea}
              rows={5}
              onInput={calcTextareaHeight}
              name="description"
              id="description"
              className="block w-full dark:bg-gray-800 dark:text-gray-200 border-none focus:ring-0 py-3 px-6"
              value={post.description}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full border-b border-gray-200 dark:border-gray-50/[0.06]"></div>
        </div>
      </IosPage>
    </form>
  );
}
