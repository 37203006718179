
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { CalendarIcon, ThumbUpIcon, UserIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import IosNavbar from "../components/ios/IosNavbar";
import IosPage from "../components/ios/IosPage";
import {
  deleteLike,
  createLike,
} from "../business/api/post-service";
import Moment from "react-moment";
import { findAllMyFriendships, findPostsSharedWithMe } from "../business/api/me-service";
import PostOverview from "../business/dto/post-overview";

export default function ExploreDream() {
  const { id } = useParams();
  const [post, setPost] = useState<PostOverview>(null);

  useEffect(() => {
    findPostsSharedWithMe(id).then((post: PostOverview) => setPost(post));
  }, []);

  const onToggleLikeClicked = (post: PostOverview) => {
    if (post.liked) {
      deleteLike(post.id)
        .then(() => {
          post.liked = false;
          post.likes--;
          setPost({ ...post });
        })
        .catch((error) => console.log("Error", error));
    } else {
      createLike(post.id)
        .then(() => {
          post.liked = true;
          post.likes++;
          setPost({ ...post });
        })
        .catch((error) => console.log("Error", error));
    }
  };

  return (
    <IosPage>
      <IosNavbar
        title={post?.title}
        titleSize="text-2xl"
        leftAction={
          <Link
            to="/explore"
            replace={true}
            className="flex items-center ml-[-24px]"
          >
            <ChevronLeftIcon className="h-7 w-7"></ChevronLeftIcon>
            <span className="ml-[-4px] pl-[4px]">Explore</span>
          </Link>
        }
      />
      {post && (
        <>
          <div className="px-6 mb-6">
            <div className="space-y-2 mb-3">
              <div className="flex items-center space-x-2">
                <UserIcon
                  className="h-4 w-4 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                />
                <span className="text-gray-500 dark:text-gray-400 text-sm font-medium">
                  {post.author.firstname}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <CalendarIcon
                  className="h-4 w-4 text-gray-400 dark:text-gray-500"
                  aria-hidden="true"
                />
                <span className="text-gray-500 dark:text-gray-400 text-sm font-medium">
                  <time dateTime="2020-12-02">
                    <Moment date={post.date} format={"lll"} />
                  </time>
                </span>
              </div>
            </div>

            <div className="text-sm text-gray-500 dark:text-gray-400 whitespace-pre-line">
              {post.description}
            </div>

            <div className="mt-6 flex justify-between space-x-8">
              <div className="flex space-x-6">
                <span className="inline-flex items-center text-sm">
                  <button
                    onClick={() => onToggleLikeClicked(post)}
                    type="button"
                    className="inline-flex space-x-2 text-gray-400 hover:text-gray-500 dark:text-gray-500"
                  >
                    {post.liked && (
                      <ThumbUpIcon
                        className="h-5 w-5 text-teal-500"
                        aria-hidden="true"
                      />
                    )}
                    {!post.liked && (
                      <ThumbUpIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                    <span className="font-medium text-gray-900 dark:text-gray-200">
                      {post.likes}
                    </span>
                    <span className="sr-only">likes</span>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </IosPage>
  );
}
